import React from "react";
import Header from "../component/header";
import {Email} from "../component/email";
import {End} from "../component/end";
import {Question} from "../component/question";
import {Rule} from "../component/rule";

export class Home extends React.Component {

    constructor({props, type}) {
        super(props);
        this.type = type;
    }

    render() {
        return(
            <div className={"page"}>
                <Header/>
                <Email type={this.type}/>
                <Rule/>
                <Question/>
                <End/>
            </div>
        );
    }
}
