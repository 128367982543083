import React from "react";
import {Next} from "./next";
import {start_url_fetch} from "../config";

export class Email extends React.Component {

    constructor({props, type}) {
        super(props);
        /* retrieve the questions */
        // switch with the other URL
        this.type = type
        if (!window.localStorage.getItem('email')) {
            fetch(start_url_fetch + "/question-" + this.type).then(r => r.text()).then(r => {
                r = JSON.parse(r)
                localStorage.setItem("all_data_question", JSON.stringify(r))
            })
        }
    }

    __error_mail() {
        document.querySelector("h3").style.transform  = "translateX(0px)";
    }

    cmd__button() {
        document.querySelector("h3").style.transform  = "translateX(-100vw)";
        fetch(start_url_fetch + "/login", {
            method: "POST",
            body: JSON.stringify({
                email: document.querySelector('#email-user').value
            })
        }).then(r => r.text())
            .then((r) => {
                /* check if it's a valid email */
                if (r === 'y') {
                    window.localStorage.setItem('email', document.querySelector('#email-user').value);
                    window.localStorage.setItem('step', "rule");
                    document.location.reload();
                }
                else {
                    this.__error_mail();
                }
        } )
    }

    render() {
        if (window.localStorage.getItem('email')) return ;
        return(
        <div>
            <div className={"container-monitor"}>
                  <div className={"wrapper-monitor"}>
                      <div className={"monitor"}>
                          <p>Welcome to DigitaLinkers Lab,enter your email to start the {this.type} test. Good Luck!</p>
                      </div>
                  </div>
              </div>
              <div className={"container-form"}>

                  <input id={"email-user"} type={"text"} placeholder={"email"}/>
                  <h3 style={{transform : "translateX(-100vw)", transition : ".5s" ,color : "#da3939"}}>Invalid email.</h3>
                  <Next title={"NEXT"} cmd={() => this.cmd__button()}/>
              </div>
        </div>
        );
    }
}
