import React from "react";
import {Next} from "./next";

export class End extends React.Component {

    constructor(props) {
        super(props);
        this.text = "YOU HAVE COMPLETED THE TEST";
    }

    reset() {
        window.localStorage.clear();
        document.location.reload()
    }

    render() {
        if (window.localStorage.getItem('step') !== "end") return;
        return(
            <div className={'container-form anim'}>
                <h2>{this.text}</h2>
                <Next title={"HOME"} cmd={() => this.reset()}/>
            </div>
        );
    }
}
