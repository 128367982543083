import React from "react";

export default class Header extends React.Component {

    render() {
        return(
            <div className={"container-header"}>
                <div className={"container-title"}>
                    <img src={"https://media.discordapp.net/attachments/1113357364536541204/1126445200500346940/dl_logo_2022_offwhite.png?width=487&height=60"} alt={"logo"}/>
                </div>
            </div>
        );
    }
}
