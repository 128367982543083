import React from "react";
import {Next} from "./next";

export class Rule extends React.Component {

    cmd__button() {
        window.localStorage.setItem('step', "q1");
        document.location.reload();
    }

    render() {
        if (window.localStorage.getItem('step')!=='rule') return ;
        return(
            <div className={"container-form"}>
                <h2 className={"title-question-form"}>WELCOME TO THE DIGITALINKERS LAB!!!</h2>
                <h2>Start the questionnaires?</h2>
                <Next title={"START"} cmd={() => this.cmd__button()}/>
            </div>
        );
    }
}
