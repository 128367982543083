import React from "react";
import {Next} from "./next";
import {start_url_fetch} from "../config";
import {Time} from "./time";


export class Question extends React.Component {

    constructor({props}) {
        super(props);
        // 0 -> choice
        // 1 -> text
        // 2 -> code
        // 3 -> question + add script in navigator for exercise
        // 4 -> code SQL
        if (!window.localStorage.getItem('step')) return;
        if (window.localStorage.getItem('step').split('q').length-1 === 0) return;
        this.possibility_question = window.localStorage.getItem("all_data_question");
        this.possibility_question = JSON.parse(this.possibility_question);
        this.possibility_question = this.possibility_question["data"];
        if (window.localStorage.getItem('questions')) {
            this.random_question = parseInt(window.localStorage.getItem('questions'));
        }
        else {
            this.random_question = 0;
        }
        this.max_question = this.possibility_question.length;
        this.type = this.possibility_question[this.random_question][0];
        this.title = this.possibility_question[this.random_question][1];
        this.response = this.possibility_question[this.random_question][2];
        this.time  = this.possibility_question[this.random_question][3];
        this.selection = 0;
        this.color_validation = '#3ABE20';
        this.color_white = "#EEEEEE";
    }

    code_pen(type) {
        // for remove display bug
        let old_word = "";
        let lst_key;
        let lst_native;
        // lst of key to detect for change colors
        if (type === "sql") {
            lst_key = ['SELECT ', 'FROM ', 'select ', "from ", "WHERE ", "where ",
                "JOIN ", "join ", "DELETE ", "delete", "ORDER BY ", "order by ","OR ",
                "or ", "AND ", "and ", "NOT ", "not ", "LIKE ", "like ", "IN ", "in ",
                "NULL ","null ", "TABLE ", "table ", "VALUES ", "values ", "ADD ", "add "];
            lst_native = ['*'];
        }
        else {
            lst_key = ['const ', 'let ', 'function ', "for ", "while ", "import ", "export ",];
            lst_native = ['console', 'window', 'document', "alert"];
        }
        setInterval(()=> {
            //change color for ide
            let user_code_with_all_color = document.querySelector('textarea').value;
            if (old_word !== user_code_with_all_color) {
                old_word = user_code_with_all_color;
                /* this is for str */
                user_code_with_all_color = user_code_with_all_color.replaceAll("'", '"');
                /* this is for number */
                for (let i = 0; i < 10; i++) {
                    user_code_with_all_color = user_code_with_all_color.replaceAll(i.toString(), "<span class='nb'>" + i.toString() + "</span>");
                }
                /* this is for key*/
                for (let i = 0; i < lst_key.length; i++) {
                    user_code_with_all_color = user_code_with_all_color.replaceAll(lst_key[i], "<span class='key'>" + lst_key[i] + "</span>");
                }
                /* this is for native */
                for (let i = 0; i < lst_native.length; i++) {
                    user_code_with_all_color = user_code_with_all_color.replaceAll(lst_native[i], "<span class='native'>" + lst_native[i] + "</span>");
                }
                /* this is also for str */
                const  user_code_with_color  = user_code_with_all_color;
                user_code_with_all_color = "";
                let start_str = false;
                for (let i = 0 ; i < user_code_with_color.length ; i++) {
                    if (user_code_with_color[i] === '"' && start_str === false) {
                        user_code_with_all_color += "<span class='str'>"+user_code_with_color[i];
                        start_str = true;
                    }
                    else if (user_code_with_color[i] === '"' && start_str) {
                        user_code_with_all_color += user_code_with_color[i]+"</span>";
                        start_str = false;
                    }
                else {
                    user_code_with_all_color += user_code_with_color[i];
                }
            }
            if (start_str) {
                user_code_with_all_color += "<span/>";
            }
            /* Display result*/
            document.querySelector('.coding-hub').innerHTML = user_code_with_all_color;
            }
        }
        ,1);
    }


    type__1() {
        return(
            <div className={'container-response choice'}>
                {
                    this.response.split(',').map((a,i)=>
                        <div onClick={() => {
                            const lst_response_div = document.querySelectorAll('.response-input');
                            for (let i = 0 ; i < lst_response_div.length ; i++) {
                                lst_response_div[i].style.background = this.color_white;
                            }
                            lst_response_div[i].style.background = this.color_validation;
                            this.selection = i;
                        }} className={'response-input'}><h4>{a}</h4></div>
                    )
                }
            </div>
        );
    }

    type__2() {
        this.code_pen("js");
        return(
            <div className={'container-response'}>
                <textarea style={{position : "absolute" , top : -1000}}/>
                <div className={"coding-hub"} onClick={()=>document.querySelector('textarea').focus()}>Copy your code here.</div>
                <div onClick={() => this.execute_code()} className={'response-input'}><h5>Tester le code</h5></div>
            </div>
        );
    }

    type__3() {
        return(
            <input type={"text"} placeholder={"your response"}/>
        );
    }

    type__5() {
        this.code_pen("sql")
        return (
            <div className={'container-response'}>
                <textarea style={{position : "absolute" , top : -1000}}/>
                <div className={"coding-hub"} onClick={()=>document.querySelector('textarea').focus()}>Copy your code here.</div>
            </div>
        )
    }


    execute_code() {
        document.head.appendChild(document.createElement('script')).text = document.querySelector('textarea').value;
    }


    cmd__next() {
        const actual_question = parseInt(window.localStorage.getItem('step').split('q')[1]);
        if (actual_question === this.possibility_question.length) {
            const lst = [window.localStorage.getItem('email')];
            for (let i = 0 ; i < this.max_question ; i++) {
                lst.push(window.localStorage.getItem('q'+i.toString()));
            }
            /* FOR SAVE RESPONSE */
            fetch(start_url_fetch + '/response',{
            method: "POST",
            body: JSON.stringify({
            response: lst
            })
            }).then(() => {
            window.localStorage.setItem('step',"end");
            document.location.reload();
            });
        }
        else {
            /* SAVE RESPONSE */
            const key = 'q' + actual_question.toString();
            switch (this.type) {
                case 0:
                    window.localStorage.setItem(key, this.selection);
                    break;
                case 1:
                    window.localStorage.setItem(key, document.querySelector('textarea').value);
                    break;
                case 2 :
                    window.localStorage.setItem(key, document.querySelector('input').value);
                    break;
                case 3 :
                    window.localStorage.setItem(key, "in google slide");
                    break;
                case 4 :
                    window.localStorage.setItem(key, document.querySelector('textarea').value);
                    break;
            }

            /* NEXT QUESTION */
            window.localStorage.setItem('questions', this.random_question + 1);
            window.localStorage.setItem('step', "q" + (actual_question + 1).toString());
            document.location.reload();
        }
    }


    render() {
        if (!window.localStorage.getItem('step')) return;
        if (window.localStorage.getItem('step').split('q').length-1 === 0) return;
        if (this.type === 3) return (<QuestionV2/>);
        this.title = this.title.split('<br>');
        return(
            <div className={"container-form"}>
                {
                    this.time
                    ?
                        <Time time={this.time}/>
                    :
                        ''
                }
                <h3 className={'score'}>{this.random_question + 1} / {this.max_question}</h3>
                <h2 className={"title-question-form"}>
                    {
                        this.title.map((a)=><h3>{a}</h3>)
                    }
                </h2>

                {
                    this.type === 0
                        ?
                        this.type__1()
                        :
                        this.type === 1
                            ?
                            this.type__2()
                            :
                            this.type === 2
                                ?
                                this.type__3()
                                :
                                this.type === 4
                                    ?
                                    this.type__5()
                                    :
                                    ''
                }
                <div style={{width : "150%",display : "flex" , justifyContent : "space-around" , alignItems : "center"}}>
                    <Next title={"NEXT"} cmd={() => this.cmd__next()}/>
                </div>
            </div>
        );
    }
}


class QuestionV2 extends React.Component {

    constructor({props}) {
        super(props);
        this.possibility_question = window.localStorage.getItem("all_data_question");
        this.possibility_question = JSON.parse(this.possibility_question);
        this.possibility_question = this.possibility_question["data"];
        this.max_question = this.possibility_question.length;
        this.random_question = 0;
    }

    type__4() {
        const a = new Function(this.response);
        setTimeout(()=>{
            a();
        },50);
        return (
            <div className={"add-with-code"} id={"add-with-code"+this.random_question.toString()}></div>
        );
    }

    cmd__next() {
        const lst = [window.localStorage.getItem('email')];
        for (let i = 0 ; i < this.max_question ; i++) {
            lst.push(window.localStorage.getItem('q'+i.toString()));
        }
        /* FOR SAVE RESPONSE */
        fetch(start_url_fetch + '/response',{
            method: "POST",
            body: JSON.stringify({
                response: lst
            })
        }).then(() => {
            window.localStorage.setItem('step',"end");
            document.location.reload();
        });
    }


    render() {
        return (
            <div>
                {
                    this.possibility_question.map((a,i) => {
                        this.random_question = i
                        this.type = this.possibility_question[this.random_question][0];
                        this.title = this.possibility_question[this.random_question][1];
                        this.response = this.possibility_question[this.random_question][2];
                        this.time  = this.possibility_question[this.random_question][3];
                        this.title = this.title.split('<br>');
                        if (this.type === 3)
                        return(
                            <div className={"container-form"}>
                                <h3 className={'score'}>{this.random_question + 1} / {this.max_question}</h3>
                                <h2 className={"title-question-form"}>
                                    {
                                        this.title.map((a) => <h3>{a}</h3>)
                                    }
                                </h2>
                                {
                                    this.type__4()
                                }
                                <div style={{width: "150%", display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                                    {
                                    this.random_question === this.possibility_question.length - 1
                                    ?
                                        <Next title={"NEXT"} cmd={() => this.cmd__next()}/>
                                    :
                                        ''
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
