import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Home} from "./page/home";

export default class App extends React.Component {
    render() {
        return(
            <BrowserRouter>
                <Routes>
                    <Route path={"cro"} element={<Home type={"cro"}/>}></Route>
                    <Route path={"analytics"} element={<Home type={"analytics"}/>}></Route>
                    <Route path={"data-analyse"} element={<Home type={"data-analyse"}/>}></Route>
                    <Route path={"*"} element={<Home type={"analytics"}/>}></Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
