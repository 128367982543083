import React from "react";

export class Next extends React.Component {

    constructor({props, title, cmd}) {
        super(props);
        this.title = title;
        this.cmd = cmd;
    }


    render() {
        return(
            <a onClick={this.cmd} className='button-next'>{this.title}<span><i className='bi bi-arrow-right'></i></span></a>
        );
    }
}
