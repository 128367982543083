import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./style/index.scss";

let isActive = true;

window.onfocus = () => {
    isActive = true;
};

window.onblur = () => {
    isActive = false;
};

setInterval(function () {
    document.title = (isActive ? 'DIGITALINKERS ITW' : 'CHEAT DETECTED');
}, 1);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);
