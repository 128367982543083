import React from "react";

export class Time extends React.Component {

    constructor({props, time}) {
        super(props);
        let i = 100;
        setInterval(() => {
            i--;
            document.querySelector(".line-time").style.transform = "translateX(-"+i.toString()+"%)";
            if (i === 0) {
                const actual_question = parseInt(window.localStorage.getItem('step').split('q')[1]);
                window.localStorage.setItem('questions',(actual_question).toString());
                window.localStorage.setItem('step',"q"+(actual_question+1).toString());
                document.location.reload();
                i = 100;
            }
        },time * 10)
    }

    render() {
        return (
            <div className={"container-time"}>
                <div className={"line-time"}></div>
            </div>
        );
    }
}
